import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"

import "./tos.scss"

export default ({ location }) => (
  <Layout className="policiesPage policiesPage--tos">
    <SEO
      title="LicenseSpring: Terms Of Service"
      path={location.pathname}
      description={
        "Review LicenseSpring's terms of service, outlining the conditions and guidelines for using our platform and accessing our software licensing solutions."
      }
    />
    <div className="container">
      <h1>LICENSESPRING TERMS AND CONDITIONS</h1>
      <div className="policiesPage__updated">
        <p>
          Last update: <b>June 19 th , 2018</b>
        </p>
      </div>
      <p>
        These terms and conditions (hereinafter collectively referred to as the
        “ <strong>Terms</strong>”) constitute a legal and binding agreement
        between <strong>Cense Data Inc.</strong>, a company incorporated under
        the laws of Canada, with registered office at 1810-928 Homer Street
        Vancouver, V6B 1T7 British Columbia, Canada, business registration no.
        81673 9726, including its subsidiaries, affiliates and contractors
        acting on its behalf (hereinafter collectively referred to as “{" "}
        <strong>We</strong>”, “ <strong>Us</strong>” or “ <strong>Our</strong>”)
        and the clients, users and their heirs, agents, successors and assigns
        (hereinafter singularly and collectively referred to as “{" "}
        <strong>You</strong>” or “ <strong>Your</strong>”) that use our website
        www.licensespring.com and its entire contents, features, services and
        functionalities (hereinafter collectively and indistinctly referred to
        as the “ <strong>Platform</strong>”) at the purpose to access and use
        the services (i.e., management of licenses of Your software products)
        offered by Us through the Platform (hereinafter collectively referred to
        as the “ <strong>Services</strong>”).
      </p>
      <p>
        {" "}
        <strong>
          IMPORTANT NOTICE: Your continued use of the Platform constitutes Your
          expressed acceptance of and agreement to be bound by these Terms, as
          in force from time to time. You further understand and acknowledge
          that also by accessing or using any Services You agree to be bound by
          these Terms. If You do not accept such Terms in their entirety, You
          must not download, access, or use the Platform and/or the Services. If
          You agree to these Terms on behalf of an entity, You represent and
          warrant that You have the authority to bind that entity to these
          Terms. In such event, “You” and “Your” will refer and apply to that
          entity or agency.
        </strong>{" "}
      </p>
      <h2>OVERVIEW</h2>
      <h3>Description of Our Services. </h3>
      <p>
        Our Services provide the following solutions: allow software developers
        to control the state of their software, as per the licensing agreement
        they have with their end users; this includes: (i) issuance of new keys
        which can control different states of the software (e.g., a trial/full
        version, or a subscription service); (ii) the customer’s desktop
        application can check the status of a license key (e.g., valid, active,
        type etc.); (iii) ability to set how many computers can use the same
        license key; (iv) ability to reset / disable license keys;
      </p>
      <p>
        provide and maintain an SDK for Windows and for Mac developers to be
        able to easily integrate Our licensing solution into their application
        projects;
      </p>
      <p>
        provide and maintain up to date documentation on the usage of the
        Platform, implementation of the SDK in their project, and integr ation
        with an e-commerce platform.
      </p>
      <h3>Rights granted. </h3>
      <p>
        Subject to these Terms, You are granted a personal, non-assignable,
        non-sublicensable, non-transferable and non-exclusive license to use the
        Platform and the related Services, as specified by in the applicable
        subscription plan entered into by You through the Platform (hereinafter
        referred to as the “ <strong>Subscription Plan</strong>”).
      </p>
      <h3>Age limit. </h3>
      <p>
        You represent and warrant that You are eighteen (18) years old or older.
      </p>
      <h3>Reservation of rights.</h3>
      <p>
        We expressly reserve all rights not expressly granted to You in these
        Terms, including, without limitation, the right to begin charging for
        any Services and/or any Platform’s features at any time, as well as the
        right to reduce any functionality thereof.
      </p>
      <h2>YOUR LICENSESPRING ACCOUNT AND PRIVATE KEY</h2>
      <h3>Account registration. </h3>
      <p>
        In order to access the Platform and the related Services, You must
        register and create an account on the Platform by providing Us with
        complete and accurate information as prompted by the registration form
        (including, without limitation, full name, username, password, etc.). In
        certain cases, You will be assigned a username and a password for Your
        account by Your institution or employer.
      </p>
      <h3>Account security.</h3>
      <p>
        You are entirely responsible for safeguarding and maintaining the
        security and confidentiality of Your account’s username and password.
        You acknowledge and accept that We will assume that any person using
        Your username and password is You. To such purpose, You undertake not to
        share the account password to any third party or to let anyone else
        access the Platform or do anything else that might jeopardize the
        security thereof. You agree to make every reasonable effort to prevent
        unauthorized third parties from accessing Your account, and You agree to
        immediately notify Us if You have reasons to believe that unauthorized
        access to Your account has occurred
      </p>
      <h3>Multiple logins.</h3>
      <p>
        If allowed by Your Subscription Plan, You may create separate logins for
        multiple users. To such purpose: (i) You shall be responsible for such
        authorized users’ compliance with these Terms as well as for all the
        activities that will occur under their accounts and as a result of their
        access to the Platform; (ii) You shall identify these users, approve
        their access to the Platform, control against unauthorized access by
        them, and maintain the confidentiality of usernames, passwords and their
        account information.
      </p>
      <h3>Private Key. </h3>
      <p>
        In order to provide You with the Services, You will be assigned a
        private key that will be associated to You, in order to encrypt the data
        of the Services provided to You. Such private key will be Your sole
        responsibility and Sect. 2.2) above shall apply. If the assigned private
        key is revoked, Services associated to You will stop working.
      </p>
      <h2>SUBSCRIPTION, FEES AND BILLING</h2>
      <h3>Subscription Plans. </h3>
      <p>
        In order to use the Platform and our related Services, You are requested
        to subscribe a Subscription Plan. You can choose among various
        Subscription Plans that differs in terms of features, number of
        accessible Services and prices.{" "}
      </p>
      <h3>Fees. </h3>
      <p>
        When You subscribe a Subscription Plan, You agree to pay any and all
        related applicable fees (if applicable), according to the specific terms
        thereof, as from time to time available through the Platform. Unless
        otherwise provided, all fees are stated in U.S. Dollars (USD) and are
        exclusive of all taxes, levies, or duties imposed by taxing authorities,
        for which You shall be responsible for the related payment, excluding
        only Canada.
      </p>
      <h3>Recurring billing. </h3>
      <p>
        Fees are billed in advance on a monthly basis. To such purpose, You
        agree to provide Us with valid, up-to-date and complete debit/credit
        card, contact and billing details. You further authorize Us to bill such
        debit/credit card on the corresponding effective date (and renewals
        ones) for the due Subscription Plan’s fees. If, for any reason, Your
        credit/debit card company refuses to pay the amount billed for the the
        Subscription Plan purchased, You agree that We may, at Our option,
        suspend or terminate Your access to the features of the Subscription
        Plan, and require You to pay the overdue amount by other means
        acceptable to Us.
      </p>
      <h3>Cancellation. </h3>
      <p>
        At any time during the Subscription Plan period, You are entitled to
        withdraw before the end of the then-current paid up month.
      </p>
      <h3>Prices change.</h3>
      <p>
        We are entitled to review the fees of any Subscription Plan and, to such
        purpose, You will be informed at least thirty (30) days before the
        expiration of the then-current Subscription Plan’s paid up month. Unless
        You decide not to accept the fees’ review by cancelling the subscription
        within the term provided in Sect. 3.4 above, such new fees shall be
        applied to the subsequent Supscription Plan’s month.
      </p>
      <h3>No refund. </h3>
      <p>In no case refunds are allowed.</p>
      <h2>YOUR RESPONSIBILITIES</h2>
      <h3>General responsibilities. </h3>
      <p>
        You are entirely responsible for Your use of the Platform and the
        Services and, to such purpose, You assure that You will not use, or
        encourage, promote, facilitate, or instruct, or induce others to use the
        Platform and/or the Services for any activity that violates any any and
        all applicable local, state, national, and international laws, rules and
        regulations (hereinafter collectively referred to as the “{" "}
        <strong>Laws</strong>”), or for any other illegal, fraudulent, harmful,
        or offensive purpose.
      </p>
      <h3>Data lawfulness. </h3>
      <p>
        Some of Our Services allow You to upload, submit, store, send or receive
        content, including text, files, images, graphics, illustrations,
        information, software, data – including, without limitations, any data
        referred to identified or identifiable natural persons, etc. –, audio,
        video, photographs and other content and material in any format
        (hereinafter collectively and indistinctly referred to as the “{" "}
        <strong>Data</strong>”). You undertakes to have all the interest and
        title in and to such Data, and to have the sole responsibility for the
        accuracy, quality, integrity, legality, reliability, and appropriateness
        thereof. In no way whatsoever We will be responsible for any Data
        uploaded, submitted, stored, sent or received through the Platform
        and/or the Services.
      </p>
      <h3>Further responsibilities. </h3>
      <p>
        You are entirely responsible for configuring the licensing solutions
        inside the Platform, as well as on the e-commerce platform (i.e.,
        Fastspring), provided that We are available to assist You for
        consultancy fee to be separately agreed. Further, You shall be entirely
        responsible also for implementing the SDK into Your software.
      </p>
      <h3>Examples of prohibited uses of the Platform / Services. </h3>
      <p>
        Examples of prohibited uses of the Application include, without
        limitation: (i) using the Platform and/or the Service without following
        the documentation and instructions provided by Us; (ii) creating,
        uploading, modifying and/or distributing any Data, or take any action
        using the Platform, that is illegal, fraudulent, harmful, or violates
        any applicable Laws; (iii) creating, uploading, modifying and/or
        distributing any Data, or take any action using the Platform, that
        violates the rights of any third party (including, without limitations,
        privacy, property rights, etc.); (iv) creating, uploading, modifying
        and/or distributing any Data, or take any action using the Platform,
        that infringes or violates the Intellectual Property Rights (as below
        defined) of another person, entity, service, product, or website; (v)
        uploading viruses or malicious codes, or take any other action using the
        Platform that could in any way compromise any functionality thereof;
        (vi) accessing any account belonging to someone else, or otherwise
        accessing the Platform using any robot, spider, scraper, or other
        automated means to access the Platform for any purpose (e.g., data
        extraction, data scraping, data mining, data harvesting, etc.); (vi)
        facilitating or encouraging any violations of these Terms.
      </p>
      <h2>PERSONAL DATA AND PROCESSING THEREOF</h2>
      <h3>Data containing Personal Data. </h3>
      <p>
        Without limiting the foregoing, You warrant and represent that the Data
        are fully compliant with the applicable Laws regarding the protection of
        any information relating to an identified or identifiable natural person
        (hereinafter such data will be collectively referred to as the “{" "}
        <strong>Personal Data</strong>”), including but not limited to the
        European General Data Protection Regulation no. 2016/679. To such
        purpose, You expressly agree, warrant and represent that: (i) You act as
        the sole “controller” of the Personal Data contained in the Data, as We
        are hereby appointed, for the Subscription Plan period, as “processor”
        of such data on Your behalf, at the purpose of providing You with the
        Services; (ii) any person whose Personal Data are contained in the Data
        has previously expressed his freely given, specific, informed and
        explicit consent to the processing of their Personal Data through the
        Platform and for the purposes consistent with the Services and these
        Terms; (iii) the Data do not contain any Personal Data revealing racial
        or ethnic origin, political opinions, religious or philosophical
        beliefs, or trade union membership, as well as genetic data, biometric
        data for the purpose of uniquely identifying a natural person, data
        concerning health or data concerning a natural person’s sex life or
        sexual orientation; (iv) You will provide Us – at Our simple request –
        with written evidences of the warranties and representations above.
      </p>
      <h3>Data backup, removal and anonymization for statistical purposes. </h3>
      <p>
        You accept and acknowledge that We do not provide any backup or
        restoration service, although We backup regularly Our systems as
        indicated in Our{" "}
        <Link to="/service-level-agreement">Service Level Agreement</Link> which
        is an integral part of these Terms and is incorporated herein by
        reference. We will erase the Data (including any private key associated
        to You) within 7 (seven) days from the termination of Your Account or We
        will transform such Data in an anonymous form for statistical purposes.
        Notwithstanding the preceding, We have the right, in addition to any
        other right afforded to Us under these Agreement, to take remedial
        actions if any Data violates any Laws or any third party right (of
        whatever nature, including but not limited to intellectual property
        right, privacy, etc.), including the removal of such Data. We shall have
        no liability to You and/or any third party in the event that We take
        such action.
      </p>
      <h3>Data Processing Addendum. </h3>
      <p>
        As long as We provide You with the Services, You appoint Us as
        “processor” of the Data uploaded on the Platform by You at the purpose
        of host, process (including, without limitation, reformat, manipulate,
        etc.) and transmit the Data with Personal Data as You may instruct
        through the Platform from time to time. In particular, in case such
        Personal Data are of individuals from the European Economic Area, by
        accepting these Terms You accept to be bound also by the Data Processing
        Addendum available at <Link to="/data-processing">LINK</Link>, which is
        an integral part of these Terms and is incorporated herein by reference.
      </p>
      <h2>AVAILABILITY, UPDATES AND MAINTENANCE</h2>
      <h3>Availability. </h3>
      <p>
        Exception made for Our obligations specifically set out in the Service
        Level Agreement (available at{" "}
        <Link to="/service-level-agreement">LINK</Link>) which is an integral
        part of these Terms and is incorporated herein by reference, We have no
        control or liability over the availability of the Platform / Services on
        a continuous or uninterrupted basis, so that from time to time the
        Platform and/or the Services may be inaccessible or inoperable for
        several reasons, including, without limitation: (i) equipment
        malfunctions, periodic maintenance procedures or repairs; (ii) causes
        beyond Our reasonable control or that are not reasonably foreseeable
        (including, without limitation, threat to security, interruption or
        failure of telecommunication or digital transmission links, hostile
        network attacks, network congestion or other failures); (iii)
        limitations due to Your particular device or hardware; (iv) Your
        violation of any relevant provision of these Terms. Furthermore, You
        agree and acknowledge that We use third-party vendors and hosting
        partners to provide Us the necessary hardware, software, networking,
        storage, and related technology required to run the Platform and the
        Services.
      </p>
      <h3>Updates and maintenance. </h3>
      <p>
        We, in Our sole discretion, reserve the right to add additional tools,
        utilities, improvements, third party applications, features or
        functions, or to provide programming fixes, updates and upgrades, to the
        Platform and/or the Services in order to improve and enhance the
        features and performance thereof (hereinafter collectively referred to
        as the “ <strong>Updates</strong>”). You expressly acknowledge and agree
        that We have no obligation to make available and/or provide any Updates.
        In case that We provide any Updates, these Terms shall automatically
        apply thereto, unless We provide other terms along with such Updates.
        Furthermore, You acknowledge that the Platform and/or the Services may
        contain automatic update technology, a feature used to provide
        maintenance (such as a bug fix, patches, enhanced functions, missing
        plug-ins, and new versions) and by installing the proper software
        initially, You consent to the transmission of standard computer
        information and the automatic downloading and installation of
        maintenance software.
      </p>
      <h2>TERM AND TERMINATION</h2>
      <h3>Term and termination. </h3>
      <p>
        These Terms are effective on the first day on which You registered Your
        account and shall remain in force for an undefined term, until
        terminated either: (i) by You, at any time, by Your request to cancel
        Your account or to unsubscribe the Subscription Plan; or (b) by Us, at
        any time, in case of Your breach of any term herein provided, without
        notice or liability of any kind in such respect.
      </p>
      <h3>Obligations surviving termination. </h3>
      <p>
        Provisions that survive termination or expiration of these Terms are
        those relating to limitation of liability, indemnification, payment and
        others which by their nature are intended to survive.
      </p>
      <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
      <h3>Our ownership.</h3>
      <p>
        We hold and retain all an any title and interest in and to the Platform
        and the Services, as well as in any Intellectual Property Right
        associated therewith and, therefore, nothing in these Terms will be
        interpreted as a transfer, in whole or in part, of rights to You or any
        other third party with reference to the Platform and the Services, other
        than those specified herein. For the purposes of these Terms, “{" "}
        <strong>Intellectual Property Rights</strong>” means all intellectual
        property and other similar proprietary rights in any jurisdiction,
        whether owned or held for use under license, whether registered or
        unregistered, including such rights in and to: (i) trademarks, service
        marks, certification marks, logos, trade names, and the goodwill
        associated with the foregoing; (ii) patents and patent applications, and
        any and all divisions, continuations, reissues, reexaminations, and
        extensions thereof; (iii) writings and other works of authorship; (iv)
        trade secrets, business, technical and know-how information, business
        processes, non-public information, proprietary information and
        confidential information, and rights to limit the use or disclosure
        thereof by any person; (v) software, including data files, source code,
        object code, application programming interfaces, databases and other
        software-related specifications and documentation; (vi) domain names,
        uniform resource locators, and Internet addresses; (vii) any and all
        technical information, software, specifications, drawings, records,
        documentation, ideas, knowledge, invention disclosures or other data;
        (viii) claims, causes of action and defenses relating to the enforcement
        of any of the foregoing.
      </p>
      <h3>Your obligations for Our ownership preservation. </h3>
      <p>
        You shall not, and shall not cause or permit others to: (i) remove or
        modify any program markings or any notice of Our Intellectual Property
        Rights or those of Our licensors; (ii) make the programs or materials
        resulting from the Services (excluding the Data) available in any manner
        to any third party for use in such third party’s business operations;
        (iii) modify, make derivative works of, disassemble, decompile, reverse
        engineer, reproduce, distribute, republish or download any part of the
        Platform, or access or use the Services in order to build or support,
        and/or assist a third party in building or supporting, products or
        Services competitive to Us; (iv) perform or disclose any benchmark or
        performance tests of the Services, the Platform or any associated
        infrastructure (such as, without limitation, network discovery, port and
        service identification, vulnerability scanning, password cracking,
        remote access testing, penetration testing, etc.); and (v) license,
        sell, rent, lease, transfer, assign, distribute, host, outsource, permit
        timesharing or service bureau use, or otherwise commercially exploit or
        make available the Services and the Platform to any third party.
      </p>
      <h3>Your feedbacks and suggestions.</h3>
      <p>
        We may freely use Your feedbacks, suggestions, or ideas in any way,
        including in future modifications of the Platform, the Services, or of
        any other products or services, advertising or marketing materials. To
        such purpose, You grant Us a perpetual, worldwide, fully transferable,
        sub-licensable, irrevocable, fully paid-up, royalty free license to use
        the feedback You provide to Us in any way. Notwithstanding the
        preceding, We will not sell, publish or share Your feedback in a way
        that could identify You without Your explicit permission.
      </p>
      <h3>Your name, trademark or logo. </h3>
      <p>
        You grant Us the right to mention Your name, trademark or logos as
        reference or case-study in Our websites, presentations, marketing
        activities or materials, or in proposals to be sent to Our clients.
      </p>
      <h2>PRIVACY AND DATA PROTECTION</h2>
      <h3>Privacy Policy.</h3>
      <p>
        You declare to have carefully read Our Privacy Policy (available at{" "}
        <Link to="/privacy">LINK</Link>) which is incorporated herein by
        reference, and got a clear understanding of how We collect, use, protect
        or otherwise handle information relating to an identified or
        identifiable person.
      </p>
      <h3>Our right to access data. </h3>
      <p>
        To the maximum extent permitted by the applicable Law, We expressly
        reserves the right to access, read, preserve, and disclose any
        information as we may reasonably believe necessary to: (i) satisfy any
        applicable Laws or any authority request; (ii) enforce these Terms
        (including investigation of potential violations thereof) or Our
        Intellectual Property Rights; (iii) detect, prevent, or otherwise
        address fraud, anti-piracy, security or technical issues (including,
        without limitation, verify a valid registration, the number of instances
        launched by the Platform, the device IP address, the version of the
        software used, etc.); (iv) conduct internal statistics and analytics on
        an anonymized basis, including tracing the use of the Platform for the
        purpose of correctly provide updates, functionalities and improvements,
        or for security reasons.
      </p>
      <h2>WARRANTY AND LIABILITY</h2>
      <h3>DISCLAIMER OF WARRANTY.</h3>
      <p>
        THE PLATFORM AND THE SERVICES ARE PROVIDED “AS IS” AND “WITH ALL
        FAULTS”, WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED
        BY THE APPLICABLE LAWS, WE DISCLAIM ANY AND ALL WARRANTIES, WHETHER
        EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES
        OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR
        PURPOSE, NON-INFRINGEMENT OR ARISING FROM COURSE OF DEALING, USAGE OR
        TRADE. TO THE FULLEST EXTENT PERMITTED OR AUTHORIZED BY LAW AND WITHOUT
        LIMITATION OF THE FOREGOING, WE DO NOT WARRANT THAT THE PLATFORM AND/OR
        THE SERVICES WILL MEET YOUR REQUIREMENTS OR THAT THE PLATFORM AND/OR THE
        SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.{" "}
        <strong>
          THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART OF
          THESE TERMS
        </strong>
        .
      </p>
      <h3>DISCLAIMER OF LIABILITY.</h3>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAWS AND EXCEPTION
        MADE FOR DEATH OR PERSONAL INJURY CAUSED BY NEGLIGENCE, FRAUDULENT
        MISREPRESENTATION, OR ANY OTHER LIABILITY TO THE EXTENT THAT SUCH
        LIABILITY CANNOT BE EXCLUDED OR LIMITED BY THE APPLICABLE LAWS, IN NO
        EVENT SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
        EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO,
        PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA OR PROFITS,
        BUSINESS INTERRUPTION, ETC.) HOWEVER CAUSED AND ON ANY THEORY OF
        LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
        NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE
        SERVICES AND/OR THE PLATFORM, EVEN IF WE ARE AWARE OF OR HAVE BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.{" "}
        <strong>
          THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART OF
          THESE TERMS
        </strong>
        .
      </p>
      <h3>EXCLUSIVE REMEDY. </h3>
      <p>
        IF ANY LIMITATION, EXCLUSION, DISCLAIMER OR OTHER PROVISION CONTAINED IN
        THESE TERMS IS HELD TO BE INVALID FOR ANY REASON BY A COURT OF COMPETENT
        JURISDICTION AND WE BECOME LIABLE THEREBY FOR LOSS OR DAMAGE THAT MAY
        LAWFULLY BE LIMITED, OUR CUMULATIVE LIABILITY TO YOU OR ANY OTHER PARTY
        FOR ANY LOSS OR DAMAGES RESULTING FROM ANY CLAIMS, DEMANDS, OR ACTIONS
        ARISING OUT OF OR RELATING TO THESE TERMS SHALL NOT EXCEED THE AGGREGATE
        FEES PAID BY YOU IN THE THREE (3) MONTHS PRECEDING THE EVENT FROM WHICH
        AROSE OUR LIABILITY. NOTWITHSTANDING THE PRECEDING,{" "}
        <strong>
          YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION
          WITH THE PLATFORM AND ANY RELATED SERVICES OR ANY OTHER GRIEVANCE
          SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO, OR USE OF,
          THE PLATFORM AND/OR THE SERVICES
        </strong>
        .
      </p>
      <h2>INDEMNIFICATION</h2>
      <h3>Indemnification.</h3>
      <p>
        You shall indemnify and hold Us harmless from and against all damages,
        losses, and expenses of any kind (including reasonable legal fees and
        costs) arose by any claim made by any third party (including, without
        limitation, any agency, public administrations, entities, etc.) against
        Us in connection with any of Your: (i) violation or breach of any term
        of these Terms; (ii) violation of any applicable Laws, whether or not
        referenced herein; (iii) violation of any rights of any third-party;
        (iv) use or misuse of the Platform.
      </p>
      <h3>Remedies. </h3>
      <p>
        You expressly agree and acknowledge that any violation of Your
        obligations pursuant to these Terms may cause Us irreparable harm and
        damage, which may not be recovered at law. Therefore, You agree that Our
        remedies for breach of these Terms may be in equity by way of injunctive
        relief, as well and any other relief available, whether in law or in
        equity.
      </p>
      <h2>MISCELLANEOUS</h2>
      <h3>Discontinuation policy. </h3>
      <p>
        The Services are subject to Our discontinuation policy, so that We
        reserve the right to discontinue all support for such Services, and/or
        for any features, online or other services or content accessible through
        the Platform, in accordance with such discontinuation policy.
      </p>
      <h3>Independent entities.</h3>
      <p>
        We are an independent contractor, so no partnership, joint venture, or
        agency relationship exists between Us and You. You and Us are each
        responsible for paying our own employees, including employment related
        taxes and insurance.
      </p>
      <h3>Force majeure. </h3>
      <p>
        We shall not be responsible for any failure to perform due to unforeseen
        circumstances or to causes beyond Our reasonable control, including but
        not limited to acts of God, war, riot, embargoes, acts of civil or
        military authorities, fire, floods, accidents, strikes, lockouts, or
        shortages of transportation, facilities, fuel, energy, labor or
        materials. In the event of any such delay, We will be excused from such
        performance to the extent it is delayed or prevented by such cause.
      </p>
      <h3>No implied waiver. </h3>
      <p>
        Any tolerance or silence by Us, even if on a continuous and reiterated
        basis, in relation to any breach or default by You of any provision of
        these Terms shall not be considered as a consent to such breaches and
        defaults and shall not affect the validity of the clause breached nor
        shall be construed as a waiver to any of the rights pertaining to Us
        pursuant to these Terms and/or the applicable Laws.
      </p>
      <h3>Governing law and arbitration.</h3>
      <p>
        These Terms are governed by the substantive and procedural Laws of
        Canada, without regard to conflict of laws provisions. Any dispute
        arising out of, or relating to, these Terms shall be settled through
        arbitration conducted by a single neutral arbitrator and with place in
        Vancouver, British Columbia, Canada. The arbitrator’s decision and award
        shall be final and binding, with some exceptions under the Canadian
        Arbitration Act, and judgment on the award may be entered in any court
        having jurisdiction. To such purpose, We and You agree that: (i) if We
        and/or You want to arbitrate a dispute, a written notice will be sent to
        the other party with a description of the dispute, all
        documents/information, and the proposed resolution; (ii) We and You will
        make attempts to resolve the conflict and, if the dispute is not
        resolved within forty-five (45) days after receipt of the notice of
        arbitration, then the dispute will be submitted to formal arbitration;
        (iii) We and You will not to pursue arbitration broadly and that any
        arbitration will be solely between You and Us (not brought on behalf of
        or together with the statement of another person), provided that if for
        any reason a Court or arbitrator holds that this restriction is
        unacceptable or unenforceable, then this agreement to arbitrate does not
        apply and the dispute and must be brought to the competent Canadian
        Courts; (iv) We and You will be responsible for the respective costs
        relating to counsel, experts, and witnesses, as well as any other
        expenses related to arbitration; (v) We and You may bring qualifying
        claims in small claims court.
      </p>
      <h3>Severability. </h3>
      <p>
        Should any part of these Terms be declared illegal or unenforceable, the
        remaining portion will remain in full force and effects.
      </p>
      <h3>Amendments. </h3>
      <p>
        We may, in our sole discretion, amend these Terms at any time by posting
        a revised version thereof on Our website at{" "}
        <Link to="/terms-of-service">
          https://licensespring.com/terms-of-service/{" "}
        </Link>
        , providing reasonable advance notice of any amendment that includes a
        change that reduces Your rights or increases Your responsibilities. Your
        continued use of the Platform and/or the Services following changes to
        the Terms after the effective date of a revised version thereof
        constitutes Your expressed acceptance of and agreement to be bound by
        these Terms as in force from time to time.
      </p>
      <h3>Entire agreement. </h3>
      <p>
        These Terms constitute the entire agreement between We and You with
        respect to its subject matter and it supersedes all prior or
        contemporaneous agreements concerning such matter (including, but not
        limited to, any prior versions of these Terms).
      </p>
    </div>
  </Layout>
)
